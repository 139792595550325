import CONSTANTS from './constants';
import Settings from './settings';

const HELPER = {

  currentDeviceTarget: () => {
    if (window.screen.availWidth >= CONSTANTS.DEVICE_DESKTOP_MIN_RES) {
      return 'desktop';
    }
    if (window.screen.availWidth >= CONSTANTS.DEVICE_TABLET_MIN_RES) {
      return 'tablet';
    }
    return 'mobile';
  },

  isForCurrentDeviceTarget(targetDevices) {
    const currentDevice = HELPER.currentDeviceTarget();
    return HELPER.contains(targetDevices, currentDevice);
  },

  hasPageTargeting(settings, url, category) {
    const { href } = window.location;
    const locationUrl = new RegExp(url, 'gi');
    const hasCurrentCategory = !!(!!category
      && (category.toLowerCase() === settings.category.toLowerCase()));
    const hasCurrentUrl = !!(!!url && !!href.match(locationUrl));

    // Has url and category
    if (url && category) {
      return hasCurrentUrl && hasCurrentCategory;
    }

    // Has url without category
    if (url && !category) {
      return hasCurrentUrl && !hasCurrentCategory;
    }

    // Has category without url
    if (!url && category) {
      return !hasCurrentUrl && hasCurrentCategory;
    }

    // Has neither url nor category
    if (!url && !category) {
      return !hasCurrentUrl && !hasCurrentCategory;
    }
    return false;
  },

  getParameterByName(name) {
    const url = window.location.href;
    const parsedName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  isPreview() {
    return !!this.getParameterByName('gPreview');
  },

  generateParams(object, params) {
    const paramsList = [];
    for (let i = 0; i < params.length; i += 1) {
      const paramValue = object[params[i]];
      if (paramValue) {
        paramsList.push(`${params[i]}=${encodeURIComponent(paramValue)}`);
      }
    }
    return paramsList.join('&');
  },

  generateParamsFromDynamicSettings(dynamicSettings) {
    return HELPER.generateParams(dynamicSettings, Object.keys(dynamicSettings));
  },

  generateParamsFromSettings(settings) {
    return HELPER.generateParams(settings, CONSTANTS.ALLOWED_CHAT_PARAMETERS);
  },

  debugMessage(msg, context) {
    const { debug } = Settings.getAll();
    if (debug) {
      HELPER.warnMessage(msg, context);
    }
  },

  warnMessage(msg, context) {
    if (console) {
      // eslint-disable-next-line no-console
      console.log(`Guuru | ${msg}`, context);
    }
  },

  contains(list, obj) {
    for (let i = 0; i < list.length; i += 1) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  },

  getValue(obj, fullPath, defaultValue) {
    const paths = fullPath.split('.');
    let current = obj;
    let i;

    for (i = 0; i < paths.length; i += 1) {
      const value = current[paths[i]];
      if (value === undefined || value === '') {
        return defaultValue;
      }
      current = value;
    }

    return current;
  },

  hasClass(el, className) {
    return el.classList.contains(className);
  },

  removeClass(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      // eslint-disable-next-line no-param-reassign
      el.className = el.className.replace(
        new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
        ' ',
      );
    }
  },
  addClass(el, className) {
    if (el.classList) {
      el.classList.add(...className.split(' '));
    } else {
      // eslint-disable-next-line no-param-reassign
      el.className += ` ${className}`;
    }
  },

  isInViewport(elem) {
    // Check weather the element has no opacity (not visible)
    const style = elem.currentStyle || window.getComputedStyle(elem);

    if (parseFloat(style.opacity) === 0 || style.visibility === 'hidden') {
      return false;
    }

    const {
      left, top, bottom, right, width,
    } = elem.getBoundingClientRect();
    const clientHeight = (
      window.innerHeight || document.documentElement.clientHeight
    );
    const clientWidth = (
      window.innerWidth || document.documentElement.clientWidth
    );
    const isInViewport = (
      top >= 0
      && (left < 0 ? left + width : left) >= 0
      && bottom <= clientHeight + 2
      && right <= clientWidth + 1
    );
    this.debugMessage(`${elem.className} isInViewport: ${isInViewport}`);
    return isInViewport;
  },
  // Checks whether an is position at the bottom of the page, ex: max 500px
  // from the bottom and if there is no space for the launcher in the corner
  shouldMoveLauncher(element, launcher) {
    try {
      const rect = element.getBoundingClientRect();
      const launcherRect = launcher.getBoundingClientRect();
      const clientHeight = (
        window.innerHeight || document.documentElement.clientHeight
      );
      const isOnBottom = (clientHeight - (rect.top || 0) < 500);
      const isOverlapping = rect.top < launcherRect.bottom
        && rect.bottom > launcherRect.top;
      const theresNoSpace = (clientHeight - rect.bottom)
        < (launcherRect.height + 20);
      const shouldMoveLauncher = isOnBottom && (isOverlapping || theresNoSpace);
      this.debugMessage(`${element.className} shouldMoveLauncher: ${shouldMoveLauncher}`);
      return shouldMoveLauncher;
    } catch (e) {
      this.debugMessage('Error checking sticky elements');
    }
    return false;
  },

  isBottomOfPageVisible() {
    return document.documentElement.clientHeight + window.scrollY >= (
      document.documentElement.scrollHeight
      || document.documentElement.clientHeight
    );
  },

  addEvent(el, type, handler) {
    if (el.attachEvent) {
      el.attachEvent(`on${type}`, handler);
    } else {
      el.addEventListener(type, handler);
    }
  },
  addEvents(el, types, handler) {
    types.forEach((type) => HELPER.addEvent(el, type, handler));
  },
  // live binding helper with CSS selector
  live(selector, event, callback, context) {
    const events = Array.isArray(event) ? event : [event];
    HELPER.addEvents(context || document, events, (e) => {
      const qs = (context || document).querySelectorAll(selector);
      if (qs) {
        let el = e.target || e.srcElement;
        let index = Array.prototype.indexOf.call(qs, el);
        while (el && index === -1) {
          el = el.parentElement;
          index = Array.prototype.indexOf.call(qs, el);
        }
        if (index > -1) callback.call(el, e);
      }
    });
  },
  appendChild(parent, element) { parent.appendChild(element); },

  insertBefore(parent, element) {
    parent.insertBefore(element, parent.childNodes[0] || null);
  },

  // used as the "smartFormWrapperAction" in the attachForm function
  replaceChild(parent, element) {
    // hide all nodes first
    let childIndex = 0;
    while (childIndex < parent.children.length) {
      // eslint-disable-next-line no-param-reassign
      parent.children[childIndex].style.display = 'none';
      childIndex += 1;
    }
    parent.insertBefore(element, parent.childNodes[0] || null);
  },

  revertReplaceChild(parent) {
    let childIndex = 0;
    while (childIndex < parent.children.length) {
      // eslint-disable-next-line no-param-reassign
      parent.children[childIndex].style.display = 'block';
      childIndex += 1;
    }
    // eslint-disable-next-line no-param-reassign
    parent.firstChild.style.display = 'none';
  },

  extend() {
    // Variables
    const extended = {};
    let deep = false;
    let i = 0;
    // eslint-disable-next-line prefer-destructuring
    const length = arguments.length;

    // eslint-disable-next-line prefer-rest-params
    const localArgs = arguments;
    if (Object.prototype.toString.call(localArgs[0]) === '[object Boolean]') {
      // eslint-disable-next-line prefer-destructuring
      deep = localArgs[0];
      i += 1;
    }

    // Merge the object into the extended object
    const merge = function (obj) {
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          // If deep merge and property is an object, merge properties
          if (deep
            && Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ) {
            extended[prop] = HELPER.extend(true, extended[prop], obj[prop]);
          } else {
            extended[prop] = obj[prop];
          }
        }
      }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i += 1) {
      const obj = localArgs[i];
      merge(obj);
    }

    return extended;
  },

  timeMinutesDiff(base, recent) {
    if (Number.isNaN(base) || Number.isNaN(recent)) {
      return 0;
    }
    const diffInMs = recent - base;
    return diffInMs / 1000 / 60;
  },

  getObjectLength(obj = {}) {
    return Object.keys(obj).length;
  },

  deprecation(message) {
    if (console && console.warn) {
      console.warn(`Guuru Deprecation | ${message}`);
    }
  },

  getCanonicalUrl() {
    const canonical = document
      .querySelector("link[rel='canonical']")
      ?.getAttribute('href');
    if (!canonical) return null;
    return canonical.startsWith('http')
      ? canonical
      : `${window.location.origin}${canonical}`;
  },

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  },

};

export default HELPER;
