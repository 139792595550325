import Settings from '../../../helpers/settings';
import Vanilla from '../../../helpers/vanilla';
import PERSISTED_MENTIONS_QUERY from './queries/getPersistedMentions';
import createPaginatedQuery from '../../../helpers/pagination';
import emptyList from './emptyList';
import contentList from './list';
import Events from '../../events';
import CONSTANTS from '../../../helpers/constants';
import { loadMorePagination, prevNextPagination } from './list/pagination';

const handlePreview = function (elementId) {
  if (!Vanilla.isPreview()) {
    return;
  }
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
};

const getInitialProperties = (color) => [
  { property: '--guuru-embed-color', value: color },
  { property: 'display', value: 'flex' },
  { property: 'flex-direction', value: 'column' },
  { property: 'margin', value: '45px 0' },
];

const getContentEmbedDiv = (color) => {
  const div = document.createElement('div');
  getInitialProperties(color).forEach(({ property, value }) => (
    div.style.setProperty(property, value)
  ));
  return div;
};

const getContentEmbed = async function (embed) {
  const {
    appId,
    mentionUrl,
    locale,
    userLocale,
  } = Settings.getAll();
  const isHorizontal = Vanilla.getParameterByName('horizontal')
    || appId.includes('atomic');

  const canonicalUrl = Vanilla.getCanonicalUrl();
  const currentUrl = window.location.href;
  const currentUrlWithoutQueryString = window.location.search
    ? currentUrl.split('?')[0]
    : undefined;
  const urls = mentionUrl
    ? [mentionUrl]
    : [canonicalUrl, currentUrl, currentUrlWithoutQueryString].filter(Boolean);

  const mentionsQuery = createPaginatedQuery(
    PERSISTED_MENTIONS_QUERY,
    {
      urls,
      id: appId,
      locale: userLocale || locale,
      first: isHorizontal ? 1 : 3,
    },
    'community.mentions',
  );

  const { data } = await mentionsQuery.fetch();

  const contentEmbedDiv = getContentEmbedDiv(embed.contentEmbedColor);

  if (!data.length) {
    if (isHorizontal) {
      contentEmbedDiv.innerHTML = emptyList(embed);
    } else {
      Vanilla.debugMessage('No mentions found');
      return null;
    }
  } else {
    contentEmbedDiv.innerHTML = await contentList(
      mentionsQuery,
      embed,
      isHorizontal ? prevNextPagination : loadMorePagination,
    );
  }

  Events.processEvent({
    id: `${CONSTANTS.GUURU_EVENT_NAMESPACE}embed:initialized`,
    data: { type: 'content', count: mentionsQuery.getCount() },
  });

  return contentEmbedDiv.outerHTML;
};

export { getContentEmbed, handlePreview };
