// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-content-body-BN3dI {
  margin-top: 20px;

  > span {
    font-size: 16px;
  }

  > p {
    margin: 12px 0 4px;
    color: #999;
    font-size: 14px;
  }

  .index-content-footer-zVNkJ {
    padding-top: 14px;
    font-size: 14px;
    display: flex;
    color: #999;

    p {
      margin: 0;
    }
  }
}

.index-expert-teaser-content-cCf70 {
  display: flex;
  align-items: center;


  .hero-avatar {
    border: inherit;
    box-shadow: inherit;
  }

  .heroes {
    margin-left: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 16px;
    gap: 2px;

    span:first-child {
      font-size: 15px;
      font-weight: 600;
    }

    span:nth-child(2) {
      font-size: 13px;
      color: #666;
      font-weight: 500;
    }
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-body": `index-content-body-BN3dI`,
	"contentBody": `index-content-body-BN3dI`,
	"content-footer": `index-content-footer-zVNkJ`,
	"contentFooter": `index-content-footer-zVNkJ`,
	"expert-teaser-content": `index-expert-teaser-content-cCf70`,
	"expertTeaserContent": `index-expert-teaser-content-cCf70`
};
export default ___CSS_LOADER_EXPORT___;
