import styles from './index.css';
import Analytics from '../../../../analytics';
import Vanilla from '../../../../../helpers/vanilla';
import Settings from '../../../../../helpers/settings';
import contentItem from '../contentItem';
import Events from '../../../../events';
import CONSTANTS from '../../../../../helpers/constants';

let currentPage = 1;

const changePrevButtonState = function (mentionsQuery, previousButton) {
  if (currentPage !== 1) {
    previousButton.removeAttribute('disabled');
  }
  if (currentPage === 1) {
    previousButton.setAttribute('disabled', 'true');
  }
};

const changeNextButtonState = function (mentionsQuery, nextButton) {
  const hasNextPage = mentionsQuery.hasMorePages();
  const mentions = mentionsQuery.getDataInMemory();

  if (!hasNextPage && mentions.length !== currentPage) {
    nextButton.removeAttribute('disabled');
  }
  if (!hasNextPage && mentions.length === currentPage) {
    nextButton.setAttribute('disabled', 'true');
  }
};

const updateContent = function (mentionsQuery) {
  const previousButton = document.getElementById('prev-button');
  const nextButton = document.getElementById('next-button');
  changeNextButtonState(mentionsQuery, nextButton);
  changePrevButtonState(mentionsQuery, previousButton);
  const mentions = mentionsQuery.getDataInMemory();
  const newMention = contentItem(mentions[currentPage - 1].node);
  const parent = document.getElementById('mentions');
  parent.innerHTML = '';
  parent.appendChild(newMention);
  document.getElementById('prev-number').innerHTML = currentPage;
};

const appendContent = function (mentionsQuery) {
  const mentions = mentionsQuery.getDataInMemory();
  const newMention = contentItem(mentions[mentions.length - 1].node);
  document.getElementById('mentions').appendChild(newMention);
};

const handlePaginatedClick = async function (buttonType, mentionsQuery) {
  const mentions = mentionsQuery.getDataInMemory();
  if (buttonType === 'loadMore') {
    await mentionsQuery.fetch(1);
    currentPage += 1;
    appendContent(mentionsQuery);
    if (!mentionsQuery.hasMorePages()) {
      document.getElementById('guuru-pagination').remove();
    }
  } else {
    if (buttonType === 'next' && mentions.length === currentPage && mentionsQuery.hasMorePages()) {
      document.getElementById('mentions').classList.add(styles.overlay);
      document.getElementById('guuru-mention-loading').classList.add(styles.loader);
      await mentionsQuery.fetch();
      document.getElementById('mentions').classList.remove(styles.overlay);
      document.getElementById('guuru-mention-loading').classList.remove(styles.loader);
    }
    currentPage = buttonType === 'prev' ? currentPage - 1 : currentPage + 1;
    updateContent(mentionsQuery);
  }
};

const initListeners = (mentionsQuery, elements) => {
  elements.forEach(({ elementId, buttonType }) => (
    Vanilla.live(elementId, ['click'], () => {
      handlePaginatedClick(buttonType, mentionsQuery);
      Analytics.trackEvent('content_embed_interaction', 'pagination');
      Events.processEvent({
        id: `${CONSTANTS.GUURU_EVENT_NAMESPACE}embed:interaction`,
        data: { type: 'content', element: 'pagination' },
      });
    })));
};

const prevNextPagination = (mentionsQuery) => {
  initListeners(mentionsQuery, [
    { elementId: '#prev-button', buttonType: 'prev' },
    { elementId: '#next-button', buttonType: 'next' },
  ]);
  const { locale = 'en' } = Settings.getAll();
  const previous = locale === 'de' ? 'VORHERIGE' : 'PREVIOUS';
  const next = locale === 'de' ? 'NÄCHSTE' : 'NEXT';

  return `
    <div class=${styles.pagination}>
      <button id="prev-button" disabled>< ${previous}</button>
      <span id="prev-number">${currentPage}</span>
      <span>|</span>
      <span>${mentionsQuery.getCount()}</span>
      <button id="next-button">${next} ></button>
    </div>
 `;
};

const loadMorePagination = (mentionsQuery) => {
  if (mentionsQuery.getCount() <= 3) return '';
  initListeners(mentionsQuery, [{ elementId: '#load-more-button', buttonType: 'loadMore' }]);
  const { locale = 'en' } = Settings.getAll();
  const loadMore = locale === 'de' ? 'MEHR ANZEIGEN' : 'SHOW MORE';

  return `
   <div id="guuru-pagination" class=${styles.pagination}>
      <button id="load-more-button">${loadMore} <i class="${styles.arrowDown}"></i></button>
    </div>
  `;
};

export {
  prevNextPagination,
  loadMorePagination,
  initListeners,
};
