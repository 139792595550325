import Launcher from './launcher';
import AutoMessages from './autoMessages';
import Vanilla from '../helpers/vanilla';
import CONSTANTS from '../helpers/constants';
import Analytics from './analytics';
import Storage from '../helpers/storage';
import Iframe from '../helpers/iframe';
import Settings from '../helpers/settings';
import Events from './events';
import PageTitle from './pageTitle';
import Grabber from './grabber';
import AutoMessagesStorage from './autoMessages/storage';

let isIframeInitialized = false;
let isVisible = false;

const initializeIframeOnce = function () {
  if (!isIframeInitialized) {
    const guuruIframe = document.querySelector('.js-guuru-iframe');
    if (guuruIframe) {
      const settings = Settings.getAll();
      // Refresh settings based on the most updated url
      settings.referer = Vanilla.getCanonicalUrl() || window.location.href;
      settings.refererPath = window.location.pathname;

      // Prepare iframe
      const iframeUrl = Iframe.getIframeUrl(settings);
      guuruIframe.contentWindow.location.replace(iframeUrl);
      isIframeInitialized = true;
    }
  }
};

const hideChat = function () {
  AutoMessages.preventAutoMessages();
  Grabber.hide();
  Vanilla.removeClass(document.body, 'guuru-chat-visible');
  Vanilla.removeClass(document.body, 'guuru-chat-visible-auto');

  const {
    launcherTransparent,
    launcherColor,
    launcherFormat,
    launcherCustomIcon,
  } = Settings.getAll();

  const color = launcherCustomIcon && launcherTransparent
    ? 'transparent'
    : launcherColor;

  const launcherButton = document.querySelector(CONSTANTS.SELECTOR.launcher);
  if (launcherButton && ['default', 'custom'].includes(launcherFormat)) {
    launcherButton.style.backgroundColor = color;
  }

  if (isVisible) {
    Analytics.trackEvent('close_chat_window');
    Events.processEvent({
      id: `${CONSTANTS.GUURU_EVENT_NAMESPACE}loader:chatClosed`,
    });
  }

  isVisible = false;

  // Store state
  Storage.removeItem(CONSTANTS.CHAT_LOADER_OPEN);
};

const showChat = function () {
  // An opened chat required the loader to be visible
  Launcher.showChatLoader();
  Grabber.hideImmediately();
  AutoMessages.preventAutoMessages();
  initializeIframeOnce();
  Vanilla.addClass(document.body, 'guuru-chat-visible');

  // Update loader color
  const launcherButton = document.querySelector(CONSTANTS.SELECTOR.launcher);
  if (launcherButton) {
    const { launcherOpenColor } = Settings.getAll();
    launcherButton.style.backgroundColor = launcherOpenColor;
    AutoMessages.hideSignal();
  }
  isVisible = true;
  Analytics.trackEvent('open_chat_window');
  Events.processEvent({
    id: `${CONSTANTS.GUURU_EVENT_NAMESPACE}loader:chatOpened`,
  });

  if (AutoMessagesStorage.settings.ids.length) {
    const { ids: messages } = AutoMessagesStorage.settings;
    messages.forEach((id) => {
      AutoMessagesStorage.setAutoMessageAsRead(id);
    });
  }

  Storage.setItem(CONSTANTS.CHAT_LOADER_OPEN, Date.now());
  PageTitle.handleResetNotificationCounter();
};

const toggleChat = function () {
  if (isVisible) {
    hideChat();
    if (!Launcher.isEnabledByUrl()) {
      Launcher.hideChatLoader();
    }
  } else {
    showChat();
  }
};

const preparePayload = function () {
  const settings = Settings.getAll();
  if (settings.payload) {
    const {
      name, email, question, smsFallBack,
    } = settings.payload;

    if (name) {
      settings.payloadName = name.trim();
    } else {
      Settings.delete('payloadName');
    }

    if (email) {
      settings.payloadEmail = email.trim();
    } else {
      Settings.delete('payloadEmail');
    }

    if (smsFallBack) {
      settings.payloadSmsFallback = smsFallBack.trim();
    } else {
      Settings.delete('payloadSmsFallback');
    }

    if (question) {
      settings.payloadQuestion = question.trim();
    } else {
      Settings.delete('payloadQuestion');
    }
  }
};

const prepareCustomMeta = function () {
  const settings = Settings.getAll();
  if (settings.customMeta) {
    settings.customMeta = JSON.stringify(settings.customMeta);
  }
};

const prepareUTM = function () {
  const settings = Settings.getAll();
  if (settings.utm) {
    Object.entries(settings.utm)
      .filter(([, value]) => value)
      .forEach(([key, value]) => {
        if (value) {
          settings[`utm_${key}`] = value;
        }
      });
  }
};

const prepareEventHandlers = function () {
  const settings = Settings.getAll();
  const chatEventHandlers = [
    {
      id: 'chat:close',
      handler: () => {
        if (isVisible) {
          hideChat();
        }
      },
    },
  ];
  settings.events = [...(settings.events ?? []), ...chatEventHandlers];
};

const prepareChatParameters = function () {
  preparePayload();
  prepareCustomMeta();
  prepareUTM();
  prepareEventHandlers();
};

const HELPER = {
  prepareChatParameters,
  setIsIframeInitialized(val) {
    isIframeInitialized = val;
  },
  isVisible() {
    return isVisible;
  },
  showChat,
  hideChat,
  toggleChat,
};

export default HELPER;
