import Vanilla from './vanilla';
import DynamicSettings from './dynamicSettings';

const defaultSpacePath = function (spaces) {
  const spacesPaths = new Map([['community', 'guurus']]);
  const [defaultSpace] = spaces || [];
  return spacesPaths.has(defaultSpace) ? spacesPaths.get(defaultSpace) : '';
};

const HELPER = {
  getChatEndpoint(settings) {
    const space = defaultSpacePath(settings.spaces);
    return `${process.env.CHAT_ENDPOINT}/${settings.appId}/${space}`;
  },

  getFormEndpoint(settings) {
    return `${process.env.FORM_ENDPOINT}/${settings.appId}/form`;
  },

  getIframeUrl(allSettings) {
    const settings = allSettings;
    const baseUrl = settings.channel === 'form'
      ? HELPER.getFormEndpoint(settings)
      : HELPER.getChatEndpoint(settings);
    const settingsParameters = Vanilla.generateParamsFromSettings(settings);
    const dynamicParameters = Vanilla.generateParamsFromDynamicSettings(
      DynamicSettings.getAll(),
    );
    return `${baseUrl}?${settingsParameters}&${dynamicParameters}`;
  },

  bindIframeEvent(element, eventId, eventHandler) {
    if (element.addEventListener) {
      element.addEventListener(eventId, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent(`on${eventId}`, eventHandler);
    }
  },
};

export default HELPER;
